import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 75 min. `}<meta itemProp="prepTime" content="PT75M" /></h5>
    <h5>{`Cook time: 75 min. `}<meta itemProp="cookTime" content="PT75M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Ground beef (or other meat), 1 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Medium onion (yellow or white), 1 diced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cumin, 1 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Smoked paprika, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Oregano, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Black beans, 2 15 oz. cans</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Canned diced tomatoes, 14.5 oz</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Lime juice, 2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Flour tortillas (at least 10-inch), 4-6</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Shredded cheese, 2 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Jalapeno peppers, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Hot sauce, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sour cream, for serving</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Large pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Large pan</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Thaw ground beef</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Dice onions</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Add beef to pot on medium-high heat.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`While it sits to brown, add onion, cumin, smoked paprika, oregano, salt, and pepper.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Once the beef has browned, break up the beef into small pieces and cook until the onion is soft.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add beans, including the liquid. Add tomatoes. Simmer and stir until liquid evaporates.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add lime juice and let it sit off the heat.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Pre-heat the pan over medium heat.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Assemble the burrito: on a tortilla, sprinkle cheese, add meat and bean filling, then sprinkle more cheese. Top with jalapeno peppers and hot sauce to taste.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Roll the burrito and place seam down in the heated pan. Cook until lightly brown, then flip over and cook until lightly brown.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Serve with sour cream.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      